import { Skeleton } from "@material-ui/lab";
import { useAuth } from "../hooks/auth";
import { Article, Paragraph } from "./Article";
import { LoginLink, SignUpLink } from "./SignInLink";

interface Props {
  children: React.ReactNode;
}

export const Protected = (props: Props) => {
  const { children } = props;
  const { isAuthenticated, isLoading } = useAuth();

  if (isAuthenticated) {
    return <>{children}</>;
  }

  if (isLoading) {
    return (
      <Article>
        <Paragraph>
          <Skeleton />
        </Paragraph>
      </Article>
    );
  }

  return (
    <Article>
      <Paragraph>
        Our wedding website is private, so we ask that you please{" "}
        <SignUpLink>sign up</SignUpLink> or <LoginLink>sign in</LoginLink>.
      </Paragraph>
    </Article>
  );
};
