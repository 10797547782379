import path from "path";
import { useParams } from "react-router-dom";
import { Page } from "../components/Page";
import { Content } from "../components/Content";

export const Dynamic = () => {
  const { src } = useParams<{ src: string }>();

  return (
    <Page access="protected">
      <Content src={path.normalize(src)} />
    </Page>
  );
};
