import * as React from "react";
import {
  TextField,
  InputProps,
  ListSubheader,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { Autocomplete as MuiAutocomplete } from "@material-ui/lab";
import { matchSorter } from "match-sorter";
import { VariableSizeList, ListChildComponentProps } from "react-window";

interface Option {
  label: string;
  value: string;
}

interface Props<T> {
  name: string;
  label: string;
  options: T[];
  // keys: Extract<keyof T, string>[]; // TODO
  keys: string[];
  value: string;
  onBlur?: InputProps["onBlur"];
  onChange?: InputProps["onChange"];
  disabled?: InputProps["disabled"];
}

export const Autocomplete = <T extends Option>(props: Props<T>) => {
  const options = [
    // Add blank option.
    { label: "", value: "" },
    ...props.options,
  ];

  return (
    <MuiAutocomplete
      ListboxComponent={
        ListboxComponent as React.ComponentType<
          React.HTMLAttributes<HTMLElement>
        >
      }
      options={options}
      getOptionLabel={(option) => option.label}
      filterOptions={(options, { inputValue }) =>
        matchSorter(options, inputValue, {
          keys: props.keys,
        })
      }
      onChange={(e, selected) => {
        // Fake it 'til you make it...
        props.onChange?.({
          target: {
            name: props.name,
            value: (selected as T)?.value || "",
          },
        } as React.ChangeEvent<HTMLInputElement>);
      }}
      onBlur={(e) => props.onBlur?.(e as React.FocusEvent<HTMLInputElement>)}
      value={
        options.find((option) => option.value === props.value) || {
          label: "",
          value: "",
        }
      }
      renderInput={(params) => <TextField {...params} label={props.label} />}
    />
  );
};

// Use react-window to improve rendering time for large number of options.
const LISTBOX_PADDING = 8; // px

function renderRow(props: ListChildComponentProps) {
  const { data, index, style } = props;
  return React.cloneElement(data[index], {
    style: {
      ...style,
      top: (style.top as number) + LISTBOX_PADDING,
    },
  });
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data: any) {
  const ref = React.useRef<VariableSizeList>(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

const ListboxComponent = React.forwardRef<HTMLDivElement>(
  function ListboxComponent(props, ref) {
    const { children, ...other } = props;
    const itemData = React.Children.toArray(children);
    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up("sm"), { noSsr: true });
    const itemCount = itemData.length;
    const itemSize = smUp ? 36 : 48;

    const getChildSize = (child: React.ReactNode) => {
      if (React.isValidElement(child) && child.type === ListSubheader) {
        return 48;
      }

      return itemSize;
    };

    const getHeight = () => {
      if (itemCount > 8) {
        return 8 * itemSize;
      }
      return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
    };

    const gridRef = useResetCache(itemCount);

    return (
      <div ref={ref}>
        <OuterElementContext.Provider value={other}>
          <VariableSizeList
            itemData={itemData}
            height={getHeight() + 2 * LISTBOX_PADDING}
            width="100%"
            ref={gridRef}
            outerElementType={OuterElementType}
            innerElementType="ul"
            itemSize={(index) => getChildSize(itemData[index])}
            overscanCount={5}
            itemCount={itemCount}
          >
            {renderRow}
          </VariableSizeList>
        </OuterElementContext.Provider>
      </div>
    );
  }
);
