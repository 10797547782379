import React, { useState } from "react";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import SunIcon from "@material-ui/icons/Brightness5";
import MoonIcon from "@material-ui/icons/Brightness2";
import {
  Toolbar,
  IconButton,
  MenuItem,
  Menu,
  Avatar,
  Button,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { useAuth } from "../hooks/auth";
import { useColorScheme } from "../hooks/colorScheme";
import { Logo } from "./Logo";
import { Nav } from "./Nav";

export const Header = () => {
  return (
    <header>
      <Toolbar>
        <Nav />
        <Logo />
        <UserInfo />
      </Toolbar>
    </header>
  );
};

const UserInfo = () => {
  const {
    user,
    isLoading,
    isAuthenticated,
    loginWithRedirect,
    logout,
  } = useAuth();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [colorScheme, setColorScheme] = useColorScheme();

  if (isLoading) {
    return <Skeleton variant="circle" />;
  }

  if (!isAuthenticated) {
    return (
      <Button
        size="small"
        onClick={() =>
          loginWithRedirect({
            appState: {
              returnTo: window.location.pathname,
            },
          })
        }
      >
        Sign in
      </Button>
    );
  }

  return (
    <>
      <IconButton
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
      >
        {user?.picture && <Avatar alt={user.name} src={user.picture} />}
        {!user && <AccountCircleIcon />}
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          button
          onClick={() => {
            if (colorScheme === "light") {
              setColorScheme("dark");
            } else {
              setColorScheme("light");
            }
          }}
        >
          {colorScheme === "dark" ? <SunIcon /> : <MoonIcon />}
          {colorScheme === "dark" ? "Light mode" : "Dark mode"}
        </MenuItem>
        <MenuItem
          button
          onClick={() => {
            logout({ returnTo: window.location.origin });
            handleClose();
          }}
        >
          Sign Out
        </MenuItem>
      </Menu>
    </>
  );
};
