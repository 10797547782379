import { Switch, Route } from "react-router-dom";
import { Home } from "./pages/Home";
import { Profile } from "./pages/Profile";
import { Style } from "./pages/Style";
import { Dynamic } from "./pages/Dynamic";

export const Routes = () => {
  return (
    <Switch>
      <Route path="/profile">
        <Profile />
      </Route>
      <Route path="/rsvp">
        <Profile />
      </Route>
      <Route path="/style">
        <Style />
      </Route>
      <Route path="/:src+">
        <Dynamic />
      </Route>
      <Route path="/">
        <Home />
      </Route>
    </Switch>
  );
};
