import { makeStyles } from "@material-ui/core/styles";
import { Paper, Grid, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  hero: (props: { image: string }) => ({
    position: "relative",
    backgroundColor: theme.palette.grey[800],
    color: theme.palette.common.white,
    marginBottom: theme.spacing(4),
    backgroundImage: `linear-gradient(to bottom right, rgba(0, 0, 0, 0.0), 80%, rgba(0, 0, 0, 0.62)), url(${props.image})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPositionX: "left",
    backgroundPositionY: "center",
  }),
  heroContent: {
    position: "relative",
    padding: theme.spacing(3),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(6),
    },
    textAlign: "right",
  },
}));

interface Props {
  title: string;
  image: string;
}

export const Hero = (props: Props) => {
  const classes = useStyles({ image: props.image });

  return (
    <Paper className={classes.hero}>
      <Grid container justify="flex-end" alignItems="flex-end">
        <Grid item md={6}>
          <div className={classes.heroContent}>
            <Typography
              component="h1"
              variant="h2"
              color="inherit"
              style={{ paddingTop: "300px" }}
            >
              {props.title}
            </Typography>
          </div>
        </Grid>
      </Grid>
    </Paper>
  );
};
