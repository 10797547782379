import { useMemo, useState } from "react";
import clsx from "clsx";
import { Formik } from "formik";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { Button, Grid, TextField } from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import { Skeleton } from "@material-ui/lab";
import { Autocomplete } from "../components/Autocomplete";
import { Page } from "../components/Page";
import { useAPI, useAPILazy } from "../hooks/api";
import { useAuth } from "../hooks/auth";
import airports from "../data/airports.json";
import { Article, Headline, Paragraph } from "../components/Article";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    profileForm: {
      marginTop: theme.spacing(2),
    },
    profileFormGrid: {
      "& .MuiTextField-root, & .MuiButton-root": {
        width: "100%",
      },
    },
    profileFormHidden: {
      display: "none",
    },
    buttonSuccess: {
      backgroundColor: green[500],
      "&:hover": {
        backgroundColor: green[700],
      },
    },
  })
);

export const Profile = () => {
  const classes = useStyles();
  const auth = useAuth();
  const { user } = auth;
  const { isLoading, error, data } = useAPI(["users", user?.sub || ""], {
    formatter: (response) => response.json(),
  });
  const [submit] = useAPILazy(["users", user?.sub || ""], {
    method: "PUT",
  });

  const airportOptions = useMemo(
    () =>
      airports.map((airport) => {
        return {
          ...airport,
          label: `${Array.from(
            new Set(
              [
                airport.name.trim(),
                airport.city.trim(),
                airport.country.trim(),
              ].filter((a) => a)
            )
          ).join(", ")} (${airport.IATA})`,
          value: airport.IATA,
        };
      }),
    []
  );

  const [success, setSuccess] = useState(false);

  const buttonClassname = clsx({
    [classes.buttonSuccess]: success,
  });

  if (error) {
    return <Page access="protected">Error: {JSON.stringify(error)}</Page>;
  }

  return (
    <Page access="protected">
      <Article>
        <Headline>Help us bring the party to you!</Headline>
        <Paragraph>
          Let us know your approximate location by choosing the airport closest
          to you below. You can come back and change this at any time.
        </Paragraph>
        <Paragraph>
          {(isLoading || !data) && <Skeleton />}
          {data && (
            <Formik
              initialValues={data}
              onSubmit={(values, { setSubmitting }) => {
                submit(values).then(() => {
                  setSubmitting(false);
                  setSuccess(true);
                });
              }}
            >
              {({
                values,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (
                <form className={classes.profileForm} onSubmit={handleSubmit}>
                  <TextField
                    type="hidden"
                    name="ID"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.ID}
                    className={classes.profileFormHidden}
                  />
                  <Grid
                    container
                    spacing={2}
                    className={classes.profileFormGrid}
                  >
                    <Grid item xs={12} sm={12}>
                      <Autocomplete
                        name="Location"
                        label="Nearest Airport"
                        options={airportOptions}
                        keys={["name", "city", "country", "IATA"]}
                        onChange={(e) => {
                          handleChange(e);
                          setSuccess(false);
                        }}
                        onBlur={handleBlur}
                        value={values.Location}
                        disabled={isSubmitting}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Button
                        type="submit"
                        disabled={isSubmitting}
                        variant="contained"
                        color="primary"
                        className={buttonClassname}
                      >
                        {!success && !isSubmitting && "Save"}
                        {!success && isSubmitting && "Saving..."}
                        {success && "Saved"}
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              )}
            </Formik>
          )}
        </Paragraph>
        <Paragraph>
          Based on how you logged in, it looks like your name is {user?.name}{" "}
          and the email we'll use to get in touch with you is {user?.email}. If
          this isn't right, let us know.
        </Paragraph>
      </Article>
    </Page>
  );
};
