import { Hero } from "../components/Hero";
import { Page } from "../components/Page";
import {
  Article,
  Event,
  Headline,
  Image,
  Subhead,
  Paragraph,
} from "../components/Article";

export const Style = () => {
  return (
    <Page access="public">
      <Hero title="Hero unit" image="/cat.jpg" />
      <Article>
        <Headline>This is a headline</Headline>
        <Subhead>This is a subhead</Subhead>
        <Paragraph>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </Paragraph>
        <Subhead>This is another subhead</Subhead>
        <Paragraph>
          Sed ut perspiciatis, unde omnis iste natus error sit voluptatem
          accusantium doloremque laudantium, totam rem aperiam eaque ipsa, quae
          ab illo inventore veritatis et quasi architecto beatae vitae dicta
          sunt, explicabo. Nemo enim ipsam voluptatem, quia voluptas sit,
          aspernatur aut odit aut fugit, sed quia consequuntur magni dolores
          eos, qui ratione voluptatem sequi nesciunt, neque porro quisquam est,
          qui dolorem ipsum, quia dolor sit amet consectetur adipisci[ng]velit,
          sed quia non numquam [do] eius modi tempora inci[di]dunt, ut labore et
          dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam,
          quis nostrum[d] exercitationem ullam corporis suscipit laboriosam,
          nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure
          reprehenderit, qui in ea voluptate velit esse, quam nihil molestiae
          consequatur, vel illum, qui dolorem eum fugiat, quo voluptas nulla
          pariatur? [33] At vero eos et accusamus et iusto odio dignissimos
          ducimus, qui blanditiis praesentium voluptatum deleniti atque
          corrupti, quos dolores et quas molestias excepturi sint, obcaecati
          cupiditate non provident, similique sunt in culpa, qui officia
          deserunt mollitia animi, id est laborum et dolorum fuga. Et harum
          quidem rerum facilis est et expedita distinctio. Nam libero tempore,
          cum soluta nobis est eligendi optio, cumque nihil impedit, quo minus
          id, quod maxime placeat, facere possimus, omnis voluptas assumenda
          est, omnis dolor repellendus. Temporibus autem quibusdam et aut
          officiis debitis aut rerum necessitatibus saepe eveniet, ut et
          voluptates repudiandae sint et molestiae non recusandae. Itaque earum
          rerum hic tenetur a sapiente delectus, ut aut reiciendis voluptatibus
          maiores alias consequatur aut perferendis doloribus asperiores
          repellat.
        </Paragraph>
      </Article>
      <Article>
        <Headline>This is an Event</Headline>
        <Event
          summary="Event Name for Calendar"
          when="Thursday, January 1 1970 from 11:00AM to 3:00PM"
          start="19700101T160000"
          end="19700101T200000"
          tz="America/New_York"
          location="123 Sesame Street, New York City, NY 10023"
          map="https://goo.gl/maps/p3JRLCdoi2C3H2Sz7"
        />
        <Image image="/cat.jpg" attribution="Photo by Amanda Wallas" />
        <Paragraph>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </Paragraph>
      </Article>
    </Page>
  );
};
