import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from "react-router-dom";
import { Link as MuiLink, LinkProps as MuiLinkProps } from "@material-ui/core";

interface Props {
  href: RouterLinkProps["to"] | MuiLinkProps["href"];
  onClick?: RouterLinkProps["onClick"] | MuiLinkProps["onClick"];
  children: RouterLinkProps["children"] | MuiLinkProps["children"];
  color?: MuiLinkProps["color"];
  underline?: MuiLinkProps["underline"];
}

export const Link = (props: Props) => {
  const { href, onClick, children, color, underline } = props;

  // Use RouterLink for internal links.
  if (href?.toString()[0] === "/") {
    // Yeah I know it's not canonical but it works.
    return (
      <MuiLink
        component={RouterLink}
        {...{ to: href, onClick, children, color, underline }}
      />
    );
  }

  return (
    <MuiLink
      target="_blank"
      {...{
        href: href as MuiLinkProps["href"],
        onClick,
        children,
        color,
        underline,
      }}
    />
  );
};
