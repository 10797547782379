import { useAuth } from "../hooks/auth";
import { Link } from "./Link";

interface Props {
  children: React.ReactNode;
  initialScreen?: "signUp" | "login";
}

export const SignInLink = (props: Props) => {
  const { children } = props;

  const { loginWithRedirect } = useAuth();
  return (
    <Link
      href={window.location.pathname}
      onClick={() =>
        loginWithRedirect({
          appState: {
            returnTo: window.location.pathname,
          },
          action: props.initialScreen ?? "login",
        })
      }
    >
      {children}
    </Link>
  );
};

export const SignUpLink = (props: Props) => {
  const { children } = props;

  return <SignInLink initialScreen="signUp">{children}</SignInLink>;
};

export const LoginLink = (props: Props) => {
  const { children } = props;

  return <SignInLink initialScreen="login">{children}</SignInLink>;
};
