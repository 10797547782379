import { Skeleton } from "@material-ui/lab";
import { useAPI } from "../hooks/api";
import { Article, Headline, Paragraph } from "./Article";
import { NotFound } from "./NotFound";
import { Markdown } from "./Markdown";

interface Props {
  src: string;
}

export const Content = (props: Props) => {
  const { isLoading, error, data } = useAPI(["static", props.src], {
    formatter: (response) => response.text(),
  });

  if (error?.status) {
    if (error.status === 404) {
      return <NotFound />;
    }

    return <p>Error: {JSON.stringify(error)}</p>;
  }

  if (isLoading || !data) {
    return (
      <Article>
        <Headline>
          <Skeleton />
        </Headline>
        <Paragraph>
          <Skeleton />
          <Skeleton />
        </Paragraph>
      </Article>
    );
  }

  return (
    <Article>
      <Markdown>{data || ""}</Markdown>
    </Article>
  );
};
