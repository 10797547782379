import { LocalStorageProvider } from "./hooks/localStorage";
import { Routes } from "./Routes";
import { Theme } from "./Theme";

export default function App() {
  return (
    <LocalStorageProvider>
      <Theme>
        <Routes />
      </Theme>
    </LocalStorageProvider>
  );
}
