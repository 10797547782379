import { Page } from "../components/Page";
import { Hero } from "../components/Hero";
import { Article, Headline, Paragraph } from "../components/Article";
import { Link } from "../components/Link";
import { SignInLink } from "../components/SignInLink";
import { useAuth } from "../hooks/auth";

export const Home = () => {
  const { isAuthenticated } = useAuth();

  return (
    <Page access="public">
      <Hero title="18 April 2021" image="/hero.jpg" />
      <Article>
        <Headline>Surprise! We got married.</Headline>
        <Paragraph>
          We kept the ceremony to immediate family only for safety, with
          everyone quarantining and getting tested ahead of time. But as the
          pandemic starts getting under control, we’d love to see you again and
          keep in touch. This website is a way to celebrate with all of you.
        </Paragraph>
        {!isAuthenticated && (
          <Paragraph>
            <SignInLink>Sign in</SignInLink> to learn more.
          </Paragraph>
        )}
        {isAuthenticated && (
          <>
            <Paragraph>
              If you are looking for a way to congratulate us, please consider
              helping us with our wedding scrapbook. Something a bit more than a
              traditional guest book, we’re looking for photographs, letters,
              words of advice, well wishes, and memories you have with us to add
              to a physical book. Check out the{" "}
              <Link href="/scrapbook">scrapbook page</Link> for more
              information.
            </Paragraph>
            <Paragraph>Looking forward to seeing you all soon!</Paragraph>
            <Paragraph>Amanda + Kevin</Paragraph>
          </>
        )}
      </Article>
    </Page>
  );
};
