import React from "react";
import { Container } from "@material-ui/core";
import { Header } from "./Header";
import { Main } from "./Main";
import { Protected } from "./Protected";

interface Props {
  access: "public" | "protected";
  children: React.ReactNode;
}

export const Page = (props: Props) => {
  const { access, children } = props;

  return (
    <Container maxWidth="md">
      <Header />
      <Main>
        {access === "public" ? (
          <>{children}</>
        ) : (
          <Protected>{children}</Protected>
        )}
      </Main>
    </Container>
  );
};
