import { decode } from "js-base64";
import ReactMarkdown from "markdown-to-jsx";
import { Event, Headline, Image, Paragraph, Subhead } from "./Article";
import { Crossword } from "./Crossword";
import { Gallery } from "./Gallery";
import { Link } from "./Link";

const MarkdownCrossword = (props: any) => {
  return <Crossword data={JSON.parse(decode(props.data))} />;
};

const MarkdownGallery = (props: any) => {
  return <Gallery photos={JSON.parse(props.photos)} />;
};

const options = {
  overrides: {
    h1: { component: Headline },
    h2: { component: Subhead },
    p: { component: Paragraph },
    a: { component: Link },
    Crossword: { component: MarkdownCrossword },
    Event: { component: Event },
    Gallery: { component: MarkdownGallery },
    Image: { component: Image },
  },
};

interface Props {
  children: string;
}

export const Markdown = (props: Props) => {
  return <ReactMarkdown options={options}>{props.children}</ReactMarkdown>;
};
