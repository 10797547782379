import React from "react";
import { makeStyles, Button, Typography, Paper } from "@material-ui/core";
import { Event as EventIcon, Map as MapIcon } from "@material-ui/icons";
import { Link } from "./Link";

const useStyles = makeStyles((theme) => ({
  content: {
    marginBottom: theme.spacing(4),
    padding: theme.spacing(3),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(6),
    },
    "& img": {
      width: "100%",
    },
  },
  image: {
    height: 140,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    marginLeft: -1 * theme.spacing(3),
    marginRight: -1 * theme.spacing(3),
    [theme.breakpoints.up("md")]: {
      marginLeft: -1 * theme.spacing(6),
      marginRight: -1 * theme.spacing(6),
    },
    marginBottom: theme.spacing(3),
    display: "flex",
    justifyContent: "flex-end",
    "& span": {
      alignSelf: "flex-end",
      marginRight: theme.spacing(1),
    },
  },
}));

export const Article = (props: { children: React.ReactNode }) => {
  const classes = useStyles();

  return <Paper className={classes.content}>{props.children}</Paper>;
};

export const Headline = (props: { children: React.ReactNode }) => {
  return (
    <Typography component="h2" variant="h3" gutterBottom>
      {props.children}
    </Typography>
  );
};

export const Subhead = (props: { children: React.ReactNode }) => {
  return (
    <Typography component="h3" variant="h4" gutterBottom>
      {props.children}
    </Typography>
  );
};

export const Paragraph = (props: { children: React.ReactNode }) => {
  return <Typography paragraph>{props.children}</Typography>;
};

export const Event = (props: {
  summary: string;
  when: string;
  start: string;
  end: string;
  tz: string;
  location: string;
  map: string;
}) => {
  const handleClick = () => {
    const url = [
      "BEGIN:VCALENDAR",
      "VERSION:2.0",
      "PRODID:-//WALLASWEAVER//WALLASWEAVERDOTCOM//EN",
      "BEGIN:VEVENT",
      `UID:${props.start}@wallasweaver.com`,
      `SUMMARY:${props.summary}`,
      `DTSTAMP;TZID=${props.tz}:${props.start}`,
      `DTEND;TZID=${props.tz}:${props.end}`,
      `LOCATION:${props.location.replaceAll(",", "\\,")}`,
      "END:VEVENT",
      "END:VCALENDAR",
    ].join("\n");
    window.open(encodeURI("data:text/calendar;charset=utf8," + url));
  };

  return (
    <Paragraph>
      <Button startIcon={<EventIcon />} onClick={handleClick}>
        {props.when}
      </Button>
      <br />
      <Link href={props.map} underline="none">
        <Button startIcon={<MapIcon />}>{props.location}</Button>
      </Link>
    </Paragraph>
  );
};

export const Image = (props: {
  image: string;
  positionY?: string;
  attribution?: string;
}) => {
  const classes = useStyles();

  return (
    <div
      className={classes.image}
      style={{
        backgroundImage: `url("${props.image}")`,
        backgroundPositionY: props.positionY ?? "center",
      }}
    >
      <Typography variant="caption">{props.attribution}</Typography>
    </div>
  );
};
