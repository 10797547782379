import { useAuth0, Auth0ContextInterface } from "@auth0/auth0-react";

export type User = {
  sub: string;
  name: string;
  email: string;
  picture: string;
};

export interface AuthContext {
  error?: Error;
  isAuthenticated: boolean;
  isLoading: boolean;
  user?: User;
  getAccessTokenSilently: Auth0ContextInterface["getAccessTokenSilently"];
  loginWithRedirect: Auth0ContextInterface["loginWithRedirect"];
  logout: Auth0ContextInterface["logout"];
}

export const useAuth = (): AuthContext => {
  const auth = useAuth0();

  return {
    error: auth.error,
    isAuthenticated: auth.isAuthenticated,
    isLoading: auth.isLoading,
    user: auth.user,
    getAccessTokenSilently: auth.getAccessTokenSilently,
    loginWithRedirect: auth.loginWithRedirect,
    logout: auth.logout,
  };
};
