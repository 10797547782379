import { createContext, useContext, useMemo, useReducer } from "react";

const Context = createContext({
  version: 0,
  get: (key: string): string | null => "",
  set: (key: string, value: string | null) => {},
});

interface Props {
  children: React.ReactNode;
}

export const LocalStorageProvider = (props: Props) => {
  const { children } = props;
  // This is just to force another render if values change.
  const [version, incrementVersion] = useReducer((version) => version + 1, 0);

  return (
    <Context.Provider
      value={{
        version,
        get: (key: string) => {
          return localStorage.getItem(key);
        },
        set: (key: string, value: string | null) => {
          value !== null
            ? localStorage.setItem(key, value)
            : localStorage.removeItem(key);
          incrementVersion();
        },
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useLocalStorage = (key: string, initial: string | null) => {
  const { get, set } = useContext(Context);

  const value = useMemo(() => {
    const persisted = get(key);
    return persisted !== null ? persisted : initial;
  }, [get, initial, key]);

  const setValue = (v: string | null) => {
    set(key, v);
  };

  return [value, setValue];
};
