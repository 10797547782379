import ReactCrossword from "@jaredreisinger/react-crossword";
import { makeStyles, Paper } from "@material-ui/core";
import { LightTheme } from "../Theme";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .crossword": {
      maxHeight: "600px",
    },
    "& .clues": {
      overflowY: "scroll",
    },
  },
}));

const Inner = (props: { data: any }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <ReactCrossword {...props} />
    </div>
  );
};

export const Crossword = (props: { data: any }) => {
  return (
    <LightTheme>
      <Paper>
        <Inner {...props} />
      </Paper>
    </LightTheme>
  );
};
