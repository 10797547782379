import {
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { Link } from "./Link";

const useStyles = makeStyles((theme) => ({
  logo: {
    flexGrow: 1,
    fontFamily: ["Amatic SC", "sans-serif"].join(","),
    letterSpacing: "0.5rem",
  },
}));

export const Logo = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isWide = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <Typography
      component="h1"
      variant="h3"
      color="inherit"
      align="center"
      noWrap
      className={classes.logo}
    >
      <Link href="/" color="inherit" underline="none">
        {isWide ? "Wallas+Weaver" : "W+W"}
      </Link>
    </Typography>
  );
};
