import { useLocalStorage } from "./localStorage";

type Preferences = {
  "color-scheme": "dark" | "light";
};

export type Preference = keyof Preferences;

export const usePreference = <T extends Preference>(
  key: T
): [Preferences[T] | null, React.Dispatch<Preferences[T]>] => {
  return (useLocalStorage(key, null) as unknown) as [
    Preferences[T] | null,
    React.Dispatch<Preferences[T]>
  ];
};
