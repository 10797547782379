import { CssBaseline, ThemeProvider } from "@material-ui/core";
import { createMuiTheme } from "@material-ui/core/styles";
import { useMemo } from "react";
import { useColorScheme } from "./hooks/colorScheme";

const fontFamily = {
  // Use for main title.
  iconic: ["Fredericka the Great", "sans-serif"].join(","),
  // Use this one for headers and such.
  stylized: ["Amatic SC", "sans-serif"].join(","),
  // Use this one for buttons, long runs of text.
  readable: ["Open Sans", "sans-serif"].join(","),
};

interface ThemeOptions {
  colorScheme: "dark" | "light";
}

export const createTheme = (options: ThemeOptions) =>
  createMuiTheme({
    palette: {
      type: options.colorScheme,
    },
    typography: {
      fontFamily: fontFamily.readable,
      h1: {
        fontFamily: fontFamily.stylized,
        fontWeight: 700,
      },
      h2: {
        fontFamily: fontFamily.stylized,
        fontWeight: 700,
      },
      h3: {
        fontFamily: fontFamily.iconic,
      },
      h4: {
        fontFamily: fontFamily.stylized,
      },
      h5: {
        fontFamily: fontFamily.stylized,
      },
      h6: {
        fontFamily: fontFamily.stylized,
      },
      body1: {
        fontFamily: fontFamily.readable,
      },
    },
    overrides: {
      // Bit of a hack for setting primary text color in a way that matches the light or dark theme.
      // Without this, the primary text color (e.g., on links) is too dark on a dark mode background.
      MuiTypography: {
        colorPrimary: {
          // These are the light and dark variants of palette.primary, hard-coded :(
          color: options.colorScheme === "dark" ? "#7986cb" : "#303f9f",
        },
      },
    },
  });

interface Props {
  children: React.ReactNode;
}

export const Theme = (props: Props) => {
  const { children } = props;

  const [colorScheme] = useColorScheme();
  const theme = useMemo(
    () =>
      createTheme({
        colorScheme,
      }),
    [colorScheme]
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};

export const LightTheme = (props: Props) => {
  const { children } = props;

  const theme = useMemo(
    () =>
      createTheme({
        colorScheme: "light",
      }),
    []
  );

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
