import { useState } from "react";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import CardGiftcardIcon from "@material-ui/icons/CardGiftcard";
import EmojiFoodBeverageIcon from "@material-ui/icons/EmojiFoodBeverage";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import BorderColorIcon from "@material-ui/icons/BorderColor";
import InfoIcon from "@material-ui/icons/Info";
// import EmailIcon from "@material-ui/icons/Email";
import FavoriteIcon from "@material-ui/icons/Favorite";
// import HelpIcon from "@material-ui/icons/Help";
import HomeIcon from "@material-ui/icons/Home";
import MenuIcon from "@material-ui/icons/Menu";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera";
import {
  IconButton,
  Drawer,
  ListItemText,
  ListItemIcon,
  List,
  ListItem,
  makeStyles,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { useHistory } from "react-router-dom";
import { useAuth } from "../hooks/auth";

const useStyles = makeStyles({
  list: {
    width: 250,
  },
});

export const Nav = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <nav>
      <IconButton
        edge="start"
        color="inherit"
        aria-label="menu"
        onClick={handleOpen}
      >
        <MenuIcon />
      </IconButton>
      <Drawer anchor="left" open={open} onClose={handleClose}>
        <div
          className={classes.list}
          role="presentation"
          onClick={handleClose}
          onKeyDown={handleClose}
        >
          <NavList />
        </div>
      </Drawer>
    </nav>
  );
};

const NavList = () => {
  const { isLoading, isAuthenticated, loginWithRedirect, logout } = useAuth();
  const history = useHistory();

  return (
    <List>
      <ListItem button onClick={() => history.push("/")}>
        <ListItemIcon>
          <HomeIcon />
        </ListItemIcon>
        <ListItemText primary="Home" />
      </ListItem>
      {isLoading && <Skeleton />}
      {/* {isAuthenticated && (
          <ListItem button onClick={() => history.push("/rsvp")}>
            <ListItemIcon>
              <EmailIcon />
            </ListItemIcon>
            <ListItemText primary="RSVP" />
          </ListItem>
        )} */}
      {isAuthenticated && (
        <ListItem button onClick={() => history.push("/about")}>
          <ListItemIcon>
            <InfoIcon />
          </ListItemIcon>
          <ListItemText primary="About Us" />
        </ListItem>
      )}
      {isAuthenticated && (
        <ListItem button onClick={() => history.push("/ceremony")}>
          <ListItemIcon>
            <FavoriteIcon />
          </ListItemIcon>
          <ListItemText primary="Ceremony" />
        </ListItem>
      )}
      {isAuthenticated && (
        <ListItem button onClick={() => history.push("/photos")}>
          <ListItemIcon>
            <PhotoCameraIcon />
          </ListItemIcon>
          <ListItemText primary="Photos" />
        </ListItem>
      )}
      {isAuthenticated && (
        <ListItem button onClick={() => history.push("/scrapbook")}>
          <ListItemIcon>
            <MenuBookIcon />
          </ListItemIcon>
          <ListItemText primary="Scrapbook" />
        </ListItem>
      )}
      {/* {isAuthenticated && (
          <ListItem button onClick={() => history.push("/faq")}>
            <ListItemIcon>
              <HelpIcon />
            </ListItemIcon>
            <ListItemText primary="FAQ" />
          </ListItem>
        )} */}
      {isAuthenticated && (
        <ListItem button onClick={() => history.push("/registry")}>
          <ListItemIcon>
            <CardGiftcardIcon />
          </ListItemIcon>
          <ListItemText primary="Registry" />
        </ListItem>
      )}
      {isAuthenticated && (
        <ListItem button onClick={() => history.push("/tea")}>
          <ListItemIcon>
            <EmojiFoodBeverageIcon />
          </ListItemIcon>
          <ListItemText primary="Tea" />
        </ListItem>
      )}
      {isAuthenticated && (
        <ListItem button onClick={() => history.push("/crossword")}>
          <ListItemIcon>
            <BorderColorIcon />
          </ListItemIcon>
          <ListItemText primary="Crossword" />
        </ListItem>
      )}
      {!isAuthenticated && (
        <ListItem button onClick={() => loginWithRedirect()}>
          <ListItemIcon>
            <AccountCircleIcon />
          </ListItemIcon>
          <ListItemText primary="Sign In" />
        </ListItem>
      )}
      {isAuthenticated && (
        <ListItem button onClick={() => logout()}>
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText primary="Sign Out" />
        </ListItem>
      )}
    </List>
  );
};
