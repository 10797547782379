import { useMediaQuery } from "@material-ui/core";
import { usePreference } from "./preference";

export const useColorScheme = (): [
  "dark" | "light",
  React.Dispatch<"dark" | "light">
] => {
  const [colorScheme, setColorScheme] = usePreference("color-scheme");

  // Default to dark mode unless user prefers light.
  const prefersLightMode = useMediaQuery("(prefers-color-scheme: light)", {
    noSsr: true, // Avoids returning the wrong value on first render.
  });

  return [colorScheme || (prefersLightMode ? "light" : "dark"), setColorScheme];
};
