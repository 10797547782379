import path from "path";
import useFetch, { UseFetchError } from "react-fetch-hook";
import { useToken } from "./token";

export const useAPI = <T,>(
  resource: string[],
  options: {
    formatter: (response: Response) => Promise<T>;
  }
) => {
  const { token } = useToken();
  const { isLoading, error, data } = useFetch<T>(
    `https://api.wallasweaver.com/${path.join(...resource)}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      depends: [...resource, token],
      formatter: (response: Response) => {
        if (!response.ok) {
          const err = new Error("Fetch error") as UseFetchError;
          err.status = response.status;
          err.statusText = response.statusText;
          throw err;
        }
        return options.formatter(response);
      },
    }
  );

  return { isLoading, error, data };
};

export const useAPILazy = (
  resource: string[],
  options: {
    method: string;
  }
) => {
  const { token } = useToken();

  const callback = async (payload: any) => {
    return fetch(`https://api.wallasweaver.com/${path.join(...resource)}`, {
      method: options.method,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
    });
  };

  return [callback];
};
