import { useEffect, useState } from "react";
import { useAuth } from "./auth";

export type Token = string | null;

export const useToken = () => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth();

  const [token, setToken] = useState<Token>(null);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    let isSubscribed = true;

    (async () => {
      if (!isAuthenticated) {
        isSubscribed && setToken(null);
        isSubscribed && setError(null);
        return;
      }

      try {
        const t = await getAccessTokenSilently({
          audience: "https://api.wallasweaver.com",
        });
        isSubscribed && setToken(t);
      } catch (e) {
        isSubscribed && setError(e);
      }
    })();

    return () => {
      isSubscribed = false;
    };
  });

  return {
    token,
    error,
  };
};
