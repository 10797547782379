import React from "react";
import ReactDOM, { render } from "react-dom";
import "./index.css";
import { Auth0Provider } from "@auth0/auth0-react";
import * as FullStory from "@fullstory/browser";
import { BrowserRouter, useHistory } from "react-router-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

// In non-production, debug accessibility.
if (process.env.NODE_ENV !== "production") {
  import("@axe-core/react").then((axe) => axe.default(React, ReactDOM, 1000));
}

FullStory.init({ orgId: "11BK4S" });

const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const history = useHistory();

  return (
    <Auth0Provider
      domain="wallasweaver.us.auth0.com"
      clientId="kZfQ3rxYym21ecCeROGUih4av2t7gKBH"
      redirectUri={window.location.origin}
      onRedirectCallback={(state) => {
        history.push(state?.returnTo || window.location.pathname);
      }}
      useRefreshTokens
      cacheLocation="localstorage"
      audience="https://api.wallasweaver.com"
    >
      {children}
    </Auth0Provider>
  );
};

render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthProvider>
        <App />
      </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
